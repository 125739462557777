@keyframes blink {
    50% {
      opacity: 0.5;
    }
  }
  
  .animate-blink {
    animation: blink 1s infinite;
  }
  
  @keyframes popup {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-popup {
    animation: popup 0.5s ease-out;
  }
  